import qs from 'qs'

import { API } from '../../config/host'

export const requestData = (action, data, method) => {
    return (
        fetch(API + action, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body: qs.stringify(data)
        })
            .then((response) => response.json())
            .catch((error) => {
                console.error(error);
            })
            
    );
}

export const requestAuth = (action, api_token) => {
    return (
        fetch(API + action, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': 'bearer ' + api_token
            },
            body: qs.stringify({ api_token: api_token })
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
    )
}
