export const Versi = "1.1.0"

// App Mode
export let MODE = 'PRODUCTION';
// export let MODE = 'DEVELOPMENT';

// Api Version
export let API_VERSION = 'V2'; //MySql
// export let API_VERSION = 'V4'; //PostgreSql

export const MAIN_HOST =
    MODE === 'PRODUCTION'
        ? 'https://belanjapasti.com/'
        : API_VERSION === 'V2'
            ? 'https://api-v2.belanjapasti.com/'
            : 'http://103.107.85.79:9071/';

export const MEDIA_HOST =
    MODE === 'PRODUCTION'
        ? 'https://s3.belanjapasti.com/'
        : 'http://103.107.85.79:9060/';

export const API =
    MAIN_HOST +
    (MODE === 'PRODUCTION'
        ? 'apps-services/whitelabel/'
        : API_VERSION === 'V2'
            ? 'apps-services/whitelabel/'
            : 'whitelabel/');

export const ImageEx = MEDIA_HOST + 'media/image/customer_wl/';
export const IMG_HOST = 'https://s3.belanjapasti.com/media/whitelabel/'
export const THUMB_HOST = MAIN_HOST + 'media/image/thumb/'
export const DEFAULT_IMG = MEDIA_HOST + 'media/image/default-img.png'
