import { Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Loader from 'react-loaders'
import { connect } from 'react-redux'

import {
    ToastContainer,
} from 'react-toastify';

import WLAuthRoute from '../../authRoute'

import { requestData, requestAuth } from '../helper/request'
import { init_auth, clear_auth } from "../../reducers/Auth"

const Customers = lazy(() => import('../../Views/Customer'));
const Dashboards = lazy(() => import('../../Views/Dashboard'));
const UserLogin = lazy(() => import('../../Views/UserPages/Login'));
const Content = lazy(() => import('../../Views/Content'));
const CustomerWhitelabel = lazy(() => import('../../Views/WhitelabelCustomer/Pages'));
const ProductWhitelabel = lazy(() => import('../../Views/WhitelabelProduct/Pages'));
const DownlineWhitelabel = lazy(() => import('../../Views/WhitelabelDownline/Pages'));
const ReportPPOB = lazy(() => import('./../../Views/Report/Detail/trxppob/'))
const ReportRefral = lazy(() => import('./../../Views/Report/Detail/refralbonus/index'))
const ReportOmset = lazy(() => import('./../../Views/Report/Omset'))
const Pengajuan = lazy(() => import('./../../Views/Pengajuan/index'))

const Markup = lazy(() => import('../../Views/Markup'));
const Setting = lazy(() => import('../../Views/Setting'));

class AppMain extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            auth: this.props.auth,
            whitelabel: JSON.parse(localStorage.getItem('BP-WL-Auth-Whitelabel')),
            data: []
        };
    }
    componentDidMount() {
        var Auth = localStorage.getItem('BP-WL-Auth')
        var AuthKey = localStorage.getItem('BP-WL-Auth-Key')
        var AuthUser = localStorage.getItem('BP-WL-Auth-Whitelabel')
        if (Auth != null && AuthKey != null && AuthUser != null)
            this.props.init_auth(Auth, AuthKey, JSON.parse(AuthUser))
    }

    request = async (action, data, method) => {
        var AuthKey = await localStorage.getItem('BP-WL-Auth-Key')
        await requestAuth('auth', AuthKey)
            .then((response) => {
                if (response.auth === false) {
                    this.props.clear_auth()
                }
            })

        if (action && data) {
            AuthKey = await localStorage.getItem('BP-WL-Auth-Key')

            data = { api_token: AuthKey, ...data }

            return requestData(action, data, method)
        }
    }
    render() {
        return (
            <Fragment>

                {/* Dashboards */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat" />
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <WLAuthRoute
                        path="/dashboard"
                        component={Dashboards}
                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                </Suspense>

                {/* Customer */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat" />
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <WLAuthRoute
                        path="/customers"
                        component={Customers}
                        request={(action, data, method = 'GET') => this.request(action, data, method)} />
                </Suspense>

                <Suspense fallback={
                    <div className='loader-container'>
                        <div className='loader-container-inner'>
                            <Loader type='ball-grid-beat'/>
                        </div>
                        <h6 className='mt-3'>
                            Please wait..
                            <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                        </h6>
                    </div>
                }>
                    <WLAuthRoute
                        path="/content"
                        component={Content}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                <Suspense fallback={
                    <div className='loader-container'>
                        <div className='loader-container-inner'>
                            <Loader type='ball-grid-beat'/>
                        </div>
                        <h6 className='mt-3'>
                            Please wait..
                            <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                        </h6>
                    </div>
                }>
                    <WLAuthRoute
                        path="/report/ppob"
                        component={ReportPPOB}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>


                <Suspense fallback={
                    <div className='loader-container'>
                        <div className='loader-container-inner'>
                            <Loader type='ball-grid-beat'/>
                        </div>
                        <h6 className='mt-3'>
                            Please wait..
                            <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                        </h6>
                    </div>
                }>
                    <WLAuthRoute
                        path="/report/refral"
                        component={ReportRefral}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                <Suspense fallback={
                    <div className='loader-container'>
                        <div className='loader-container-inner'>
                            <Loader type='ball-grid-beat'/>
                        </div>
                        <h6 className='mt-3'>
                            Please wait..
                            <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                        </h6>
                    </div>
                }>
                    <WLAuthRoute
                        path="/report/omset"
                        component={ReportOmset}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                <Suspense fallback={
                    <div className='loader-container'>
                        <div className='loader-container-inner'>
                            <Loader type='ball-grid-beat'/>
                        </div>
                        <h6 className='mt-3'>
                            Please wait..
                            <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                        </h6>
                    </div>
                }>
                    <WLAuthRoute
                        path="/pengajuan/limit"
                        component={Pengajuan}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                <Suspense fallback={
                    <div className='loader-container'>
                        <div className='loader-container-inner'>
                            <Loader type='ball-grid-beat'/>
                        </div>
                        <h6 className='mt-3'>
                            Please wait..
                            <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                        </h6>
                    </div>
                }>
                    <WLAuthRoute
                        path="/customer"
                        component={CustomerWhitelabel}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                <Suspense fallback={
                    <div className='loader-container'>
                        <div className='loader-container-inner'>
                            <Loader type='ball-grid-beat'/>
                        </div>
                        <h6 className='mt-3'>
                            Please wait..
                            <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                        </h6>
                    </div>
                }>
                    <WLAuthRoute
                        path="/product"
                        component={ProductWhitelabel}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                <Suspense fallback={
                    <div className='loader-container'>
                        <div className='loader-container-inner'>
                            <Loader type='ball-grid-beat'/>
                        </div>
                        <h6 className='mt-3'>
                            Please wait..
                            <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                        </h6>
                    </div>
                }>
                    <WLAuthRoute
                        path="/downline"
                        component={DownlineWhitelabel}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Markup*/}
                <Suspense fallback={

                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <WLAuthRoute
                        path="/markup"
                        component={Markup}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Seting*/}
                <Suspense fallback={

                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <WLAuthRoute
                        path="/setting"
                        component={Setting}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                {/* Users Login*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise" />
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>{this.state.whitelabel ? this.state.whitelabel.whitelabel_name : ''}</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route
                        path="/loginCms"
                        render={(props) => {
                            return <UserLogin
                                request={(action, data, method = 'GET') => requestData(action, data, method)}
                                {...props} />
                        }} />
                </Suspense>


                <Route exact path="/" render={() => <Redirect to="/loginCms" />} />
                <ToastContainer />
            </Fragment>
        )
    }
};

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    whitelabel: state.Auth.whitelabel,
    api_token: state.Auth.api_token,
})

const mapDispatchToProps = dispatch => ({
    init_auth: (api_token, auth_key, data) => dispatch(init_auth(api_token, auth_key, data)),
    clear_auth: () => dispatch(clear_auth()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppMain)
